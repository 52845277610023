<template>
  <v-container fluid class="pa-0">
    <v-row dense>
      <v-col cols="12">
        <div class="text-h6">Which divisions are you registering for?</div>
        <v-checkbox
          readonly
          v-for="d in divisions"
          :key="d.id"
          :label="d.label"
          v-model="d.v"
          @click.stop="() => { !d.disabled && selectDivision(d.id) }"
          color="success"
          hide-details
          :disabled="d.disabled"
        >
          <template v-slot:label>
            {{d.label}}
            <v-btn color="color3" text icon small v-if="d.v">
              <v-icon>fas fa-pencil</v-icon>
            </v-btn>
          </template>
        </v-checkbox>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialog"
      scrollable
      max-width="500px"
      transition="dialog-transition"
      persistent
    >
      <v-card v-if="division">
        <v-toolbar color="color1 color1Text--text">
          <v-toolbar-title>{{division.name}}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            color="color2 color2Text--text"
            small fab
            @click.stop="dialog = false"
          >
            <v-icon>fas fa-times</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-expand-transition>
            <v-row dense v-if="divisionTeams.length === 0">
              <v-col cols="12">
                <div class="text-h6">How many {{division.teamLabel.toLowerCase()}} are you registering?</div>
                <v-select
                  :items="numbers"
                  v-model="teamCount"
                  label="Team Count"
                  @input="ensureTeams"
                ></v-select>
              </v-col>
            </v-row>
          </v-expand-transition>
          <v-expand-transition>
            <v-row dense v-if="divisionTeams.length > 0">
              <v-col cols="12" v-for="(t, i) in teams" :key="`team-name-${i}`" :class="{ hide: t.divisionId !== divisionId }">
                <v-text-field
                  label="Team Name"
                  v-model="t.name"
                  hide-details
                  color="color3"
                >
                  <template v-slot:append-outer>
                    <v-btn color="error" text icon small @click.stop="deleteTeam(i)">
                      <v-icon>fas fa-trash</v-icon>
                    </v-btn>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="12" v-if="false">
                <v-select
                  :items="tournament.orgRegFlowDivisions"
                  item-text="name"
                  item-value="id"
                  v-model="t.divisionId"
                  label="Division"
                  hide-details
                  :solo="!t.divisionId"
                  color="color3"
                  item-color="color3"
                  :disabled="!!defaultDivision"
                ></v-select>
              </v-col>
              <v-col cols="12" class="text-center" v-if="divisionTeams.length < this.limit">
                <v-btn color="color3" text @click.stop="addTeam">add a {{division.teamLabelSingle}}</v-btn>
              </v-col>
            </v-row>
          </v-expand-transition>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-end">
          <v-btn
            color="color3 color3Text--text"
            small
            fab
            @click.stop="dialog = false"
          >
            <v-icon>fas fa-caret-right</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: ['teamInfo', 'active', 'clubBid'],
  data () {
    return {
      teamCount: null,
      divisionId: null,
      dialog: false
    }
  },
  computed: {
    ...mapGetters(['tournament']),
    division () {
      return this.divisionId && this.tournament.publicDivisions.find(f => f.id === this.divisionId)
    },
    limit () {
      const l = this.divisionId && this.clubBid && this.clubBid.limits.find(f => f.tournamentDivisionId === this.divisionId)
      return l ? (l.limit - (l.uses || 0)) : -1
    },
    // clubBidICart () {
    // },
    divisions () {
      return this.tournament.publicDivisions.map(d => {
        const limit = this.clubBid && this.clubBid.limits.find(f => f.tournamentDivisionId === d.id)
        const limitN = limit ? (limit.limit - limit.uses) : 0
        return {
          id: d.id,
          name: d.name,
          count: this.teams.filter(f => f.divisionId === d.id).length,
          limited: this.tournament.is('club-bids'),
          limit: limitN < 0 ? 0 : limitN,
          get label () {
            const l = this.limited ? `of ${this.limit} ` : ''
            return this.count === 0 && !this.limited ? this.name : `${this.name} (${this.count} ${l}teams)`
          },
          get v () {
            return this.count > 0
          },
          get disabled () {
            return this.limited && this.limit < 1
          }
        }
      })
    },
    divisionTeams () {
      return this.divisionId && this.teams.filter(f => f.divisionId === this.divisionId)
    },
    teams () {
      return this.teamInfo.teams
    },
    numbers () {
      return Array.from({ length: 100 }, (_, i) => i + 1)
    },
    defaultDivision () {
      const o = this.tournament.orgRegFlowDivisions
      return o.length === 1 ? o[0].id : null
    },
    valid () {
      return this.teams && this.teams.length && !this.teams.find(f => !f.name || !f.divisionId)
    }
  },
  methods: {
    selectDivision (d) {
      this.divisionId = d
      this.dialog = true
    },
    addTeam () {
      this.teamCount = this.divisionTeams.length + 1
      this.ensureTeams()
    },
    ensureTeams () {
      if (this.limit > -1) {
        if (this.teamCount > this.limit) {
          this.teamCount = this.limit
        }
      }
      var n = this.divisionTeams.length + 1
      const addLabel = this.teamCount > 1
      for (n; n <= this.teamCount; n++) {
        const label = addLabel ? ` ${this.division.teamLabelSingle} ${n}` : ''
        this.teams.push({
          id: this.teamInfo.id,
          name: `${this.teamInfo.name}${label}`,
          divisionId: this.divisionId
        })
      }
      n--
      for (n; n > this.teamCount; n--) {
        this.teams.pop()
      }
      this.teamCount = null
    },
    deleteTeam (i) {
      console.log(i)
      this.teamInfo.teams.splice(i, 1)
    },
    reset () {
      this.teamCount = null
    },
    onActive () {
      if (this.active && this.teams.length === 0 && this.divisions.length === 1) {
        this.divisionId = this.divisions[0].id
        this.dialog = true
      }
    }
  },
  watch: {
    valid: function (v) {
      this.$emit('valid-change', v)
    },
    active: 'onActive'
  },
  mounted () {
    this.$nextTick(() => {
      this.$emit('valid-change', this.valid)
    })
    this.onActive()
  }
}
</script>

<style scoped>
.hide {
  display: none;
}
</style>
